import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout darkText>
    <h1>Nie znaleziono</h1>
  </Layout>
);

export default NotFoundPage;
